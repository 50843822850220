export interface AnalyticsService {
  name: string
  isEnabled: boolean
  isInternal: boolean
  initialize(): Promise<void>
  setUserId(userId: string): Promise<void>
  trackEventAsDefault(event: string, properties: Record<string, any>): Promise<void>
  trackScreenView(properties: any): Promise<void>
  trackProductView(product: any): Promise<void>
  trackAddToCart(productWithState: any): Promise<void>
  trackInitCheckout(preferences: any): Promise<void>
  trackPurchase(order: any, extraProperties: any): Promise<void>
}
export interface ImplementationOptions {
  verbose?: boolean
}

export interface AnalyticsServicesController {
  initAll(): Promise<void>
  setUserIdAll(userId: string): Promise<void>
  trackProductViewOnAll(
    product: Record<string, any>,
    extraProperties?: Record<string, any>,
  ): Promise<void>
  trackAddToCartOnAll(
    productWithState: Record<string, any>,
    extraProperties?: Record<string, any>,
  ): Promise<void>
  trackEventOnAll(event: EcommerceEvent, properties: Record<string, any>): Promise<void>
  trackEventAsDefaultOnInternal(
    event: InternalEvent,
    properties?: Record<string, any>,
  ): Promise<void>
  trackScreenViewAll(product: any): Promise<void>
  trackInitCheckoutOnAll(
    cart: Record<string, any>,
    extraProperties?: Record<string, any>,
  ): Promise<void>
  trackPurchaseOnAll(
    order: Record<string, any>,
    extraProperties?: Record<string, any>,
  ): Promise<void>
}

export type EcommerceEvent = 'ProductView' | 'AddToCart' | 'InitCheckout' | 'Purchase'

export type InternalEvent = (typeof INTERNAL_EVENTS)[keyof typeof INTERNAL_EVENTS]

export const INTERNAL_EVENTS = Object.freeze({
  orderPageViewed: 'order-page-viewed',
  addressStartViewed: 'address-start-viewed',
  addressModalViewed: 'address-modal-viewed',
  deliveryTypeScheduled: 'delivery-type-scheduled',
  addressSubmitted: 'address-submitted',
  bannerViewed: 'banner-viewed',
  bannerClicked: 'banner-clicked',
  loginStarted: 'login-started',
  authSuccessful: 'auth-successful',
  cartPageViewed: 'cart-page-viewed',
  continueToCheckout: 'continue-to-checkout',
  upsellingViewed: 'upselling-viewed',
  tipAmountChanged: 'tip-amount-changed',
  productEdited: 'product-edited',
  couponAdded: 'coupon-added',
  paymentSubmitted: 'payment-submitted',
})
